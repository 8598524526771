<template>
  <v-container id="users-table" fluid tag="section">
    <base-material-card
      icon="mdi-account"
      inline
      color="secondary"
      class="px-5 py-3 mb-5"
    >
      <template v-slot:heading><v-icon large>mdi-account</v-icon></template>
      <template v-slot:after-heading
        ><h1 class="h5">
          <b>{{ $tc("banker", 2) }}</b>
        </h1></template
      >
      <v-btn
        color="primary"
        rounded
        @click="add_user(null)"
        absolute
        fab
        top
        right
        small
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <Table :changePageRouterPush="false" />
    </base-material-card>
  </v-container>
</template>

<script>
import Table from "@/views/pages/user/index/table/tabs/BankersTable";

export default {
  name: "BankersTable",

  components: {
    Table,
  },

  data: () => ({
    tab: 0,
  }),
  async created() {},
  computed: {},
  methods: {
    add_user: function () {
      this.$router.push({
        path: `/banker/add`,
      });
    },
  },
};
</script>
